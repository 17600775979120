/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
*/

import * as THREE from 'three'
import React, { useRef } from 'react'
import { useGLTF } from '@react-three/drei'
import { GLTF } from 'three-stdlib'

type GLTFResult = GLTF & {
  nodes: {
    Mesh2__18_0: THREE.Mesh
    Mesh2_FrontColor_0: THREE.Mesh
    Mesh3__18_0: THREE.Mesh
    Mesh3_FrontColor_0: THREE.Mesh
    Mesh1002__18_0: THREE.Mesh
    Mesh4__50_0: THREE.Mesh
    Mesh4_frame_0: THREE.Mesh
  }
  materials: {
    material: THREE.MeshStandardMaterial
    FrontColor: THREE.MeshStandardMaterial
    material_3: THREE.MeshStandardMaterial
    frame: THREE.MeshStandardMaterial
  }
}

export default function Picture({ ...props }: JSX.IntrinsicElements['group']) {
  const ITEM_URI = `https://assets.unegma.net/moonland.land/photo.glb`;

  const group = useRef<THREE.Group>(null!)
  const { nodes, materials } = useGLTF(ITEM_URI, 'https://www.gstatic.com/draco/versioned/decoders/1.4.1/') as GLTFResult
  return (
    <group ref={group} {...props} dispose={null}>
      <group >
        <mesh castShadow receiveShadow geometry={nodes.Mesh2__18_0.geometry} material={materials.material} />
        <mesh castShadow receiveShadow geometry={nodes.Mesh2_FrontColor_0.geometry} material={materials.FrontColor} />
        <mesh castShadow receiveShadow geometry={nodes.Mesh3__18_0.geometry} material={materials.material} />
        <mesh castShadow receiveShadow geometry={nodes.Mesh3_FrontColor_0.geometry} material={materials.FrontColor} />
        <mesh castShadow receiveShadow geometry={nodes.Mesh1002__18_0.geometry} material={materials.material} />
        <mesh castShadow receiveShadow geometry={nodes.Mesh4__50_0.geometry} material={materials.material_3} />
        <mesh castShadow receiveShadow geometry={nodes.Mesh4_frame_0.geometry} material={materials.frame} />
      </group>
    </group>
  )
}
