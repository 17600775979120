import * as THREE from 'three'
import React, {useRef, useState} from 'react'
import { useGLTF, Sparkles } from '@react-three/drei'
import { GLTF } from 'three-stdlib'
import {useFrame} from "@react-three/fiber";

type GLTFResult = GLTF & {
  nodes: {
    frostie: THREE.Mesh
  }
  materials: {
    frostie: THREE.MeshStandardMaterial
  }
}

export default function Frostie({ ...props }: JSX.IntrinsicElements['group']) {
  const ITEM_URI = `https://assets.unegma.net/moonland.land/frostie.glb`;

  const group = useRef<THREE.Group>(null!)
  const { nodes, materials } = useGLTF(ITEM_URI, 'https://www.gstatic.com/draco/versioned/decoders/1.4.1/') as GLTFResult
  const [start] = useState(() => Math.random() * 3000)


  useFrame((state, delta) => {

    group.current.rotation.x = Math.PI / 2 + (start + state.clock.elapsedTime * Math.PI) / 10
    // group.current.rotation.x = Math.PI / 2 + (Math.sin(start + state.clock.elapsedTime) * Math.PI) / 10
    group.current.rotation.y = (start + state.clock.elapsedTime * Math.PI) / -15
    // group.current.rotation.y = (Math.sin(start + state.clock.elapsedTime) * Math.PI) / -15
    // group.current.rotation.y = 1.8
    // setInteractivePosition({x: group.current.position.x, y: group.current.position.y, z: group.current.position.z});

    // let factor = 1 + Math.random() - 0.9;
    // let speed = 1;

    // @ts-ignore
    // group.current.rotation.y += Math.sin((delta * factor) / 5) * Math.cos((delta * factor) / 5) * 1.5 // these control how quickly it rotates around a central pivot point
    // mixer.update(delta * speed)
  })

  // useFrame((scene, delta) => {
  //   mixer?.update(delta)
  // });

  return (
    <group ref={group} {...props} dispose={null}>
      <Sparkles count={400} scale={[9,20,2]} rotation={[0.12,4,0.2]} size={90} speed={4} />
      {/*<Sparkles count={400} position={[0,-5,]} scale={[5,7,2]} rotation={[0.15,4,0.2]} size={90} speed={4} />*/}
      {/*<Sparkles count={400} scale={[10,27,2]} rotation={[0.15,4,0.2]} size={90} speed={4} />*/}
      <mesh castShadow receiveShadow geometry={nodes.frostie.geometry} material={materials.frostie} />
    </group>
  )
}
