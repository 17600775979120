import React, {Suspense, useEffect, useState} from "react";
import { Source } from '../types/SourceEnum';
import { Scene } from '../types/SceneEnum';
import SpaceScene from "./Scenes/SpaceScene";
import EarthScene from "./Scenes/EarthScene";
import CoworkScene from "./Scenes/CoworkScene";
import NFTGallery from "./NFTGallery";
import GalleryScene from "./Scenes/GalleryScene";
import PlinthScene from "./Scenes/PlinthScene";
import Ratty from "./Ratty";
import Frostie from "./Frostie";
import Picture from "./Picture";

export default function MainCanvas({gallery, handleOpen, displayMode, scene, source, handleYouClickedMe, vrMode}: any) {

  const [shape, setShape] = useState('square');
  const [animateRatty, setAnimateRatty] = useState(false);

  useEffect(() => {
    // todo change these to use enum/type or interface or whichever is the most recent correct typescript way
    if (source === Source.Wallet) {
      setShape('square');
    } else if (source === Source.POAP) {
      setShape('disk');
    }
  }, [source, shape, setShape]); // todo check these


  // todo try this https://codesandbox.io/s/0c5hv9?file=/src/App.js:1183-1721
  // const Sphere = ({ size = 1, amount = 50, color = 'white', emissive, glow, ...props }) => (
  //   <mesh {...props}>
  //     <sphereGeometry args={[size, 64, 64]} />
  //     <meshPhysicalMaterial roughness={0} color={color} emissive={emissive || color} envMapIntensity={0.2} />
  //     <Glow scale={size * 1.2} near={-25} color={glow || emissive || color} />
  //     <Sparkles count={amount} scale={size * 2} size={6} speed={0.4} />
  //     <Shadow rotation={[-Math.PI / 2, 0, 0]} scale={size} position={[0, -size, 0]} color={emissive} opacity={0.5} />
  //   </mesh>
  // )

  return (
    <>
      {/*todo add switch with fallback?*/}

      { scene === Scene.Space && (
        <>
          <SpaceScene handleYouClickedMe={handleYouClickedMe} animateRatty={animateRatty} setAnimateRatty={setAnimateRatty} />
          <NFTGallery gallery={gallery} vrMode={vrMode} displayMode={displayMode} handleOpen={handleOpen} shape={shape} />

          <pointLight position={[0, 0, 10000]} intensity={0.8} />

          <Suspense fallback={null}>
            <Ratty animateRatty={animateRatty} position={[0,3.85,0]} scale={0.01} />
            <Frostie position={[-30,0,0]} />

            <Picture position={[2,4.4,0]} scale={0.5} rotation={[-0.9,-1,-0.9]} />
            {/* !!!!!!! todo try these !!!!!!!!!*/}
            {/*<hemisphereLight intensity={0.5} color="white" groundColor="black" />*/}
            {/*<Environment files={"https://dl.polyhaven.org/file/ph-assets/HDRIs/hdr/2k/evening_road_01_2k.hdr"} ground={{ height: 5, radius: 40, scale: 20 }} />*/}
            {/*<Sphere color="white" amount={50} emissive="green" glow="lightgreen" position={[1, 1, -1]} />*/}
            {/*<Sphere color="white" amount={30} emissive="purple" glow="#ff90f0" size={0.5} position={[-1.5, 0.5, -2]} />*/}
            {/*<Sphere color="lightpink" amount={20} emissive="orange" glow="#ff9f50" size={0.25} position={[-1, 0.25, 1]} />*/}
            {/*<ContactShadows renderOrder={2} color="black" resolution={1024} frames={1} scale={10} blur={1.5} opacity={0.65} far={0.5} />*/}
            {/*<BakeShadows />*/}


          </Suspense>
        </>
      )}

      { scene === Scene.Earth && (
        <>
          <EarthScene handleYouClickedMe={handleYouClickedMe} />
          <NFTGallery gallery={gallery} vrMode={vrMode} displayMode={displayMode} handleOpen={handleOpen} shape={shape} />
        </>
      )}

      { scene === Scene.Cowork && (
        <>
          <CoworkScene />
          <NFTGallery gallery={gallery} vrMode={vrMode} displayMode={displayMode} handleOpen={handleOpen} shape={shape} />
        </>
      )}

      { scene === Scene.Gallery && (
        <>
          <GalleryScene gallery={gallery} vrMode={vrMode} displayMode={displayMode} handleOpen={handleOpen} shape={shape} />
        </>
      )}


      { scene === Scene.Plinth && (
        <>
          <PlinthScene gallery={gallery} vrMode={vrMode} displayMode={displayMode} handleOpen={handleOpen} shape={shape} />
        </>
      )}
    </>
  )
}
