/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
*/

import * as THREE from 'three'
import React, {useEffect, useRef, useState} from 'react'
import { useGLTF, useAnimations } from '@react-three/drei'
import { GLTF } from 'three-stdlib'
import {useFrame} from "@react-three/fiber";

type GLTFResult = GLTF & {
  nodes: {
    Eye_Left_Eye_Shine_0: THREE.Mesh
    Eye_Left_Eyes_0: THREE.Mesh
    Eye_Right_Eye_Shine_0: THREE.Mesh
    Eye_Right_Eyes_0: THREE.Mesh
    Object_7: THREE.SkinnedMesh
    Object_9: THREE.SkinnedMesh
    _rootJoint: THREE.Bone
  }
  materials: {
    Eye_Shine: THREE.MeshStandardMaterial
    Eyes: THREE.MeshStandardMaterial
    material: THREE.MeshStandardMaterial
    ['default']: THREE.MeshStandardMaterial
  }
}

type ActionName = 'Carry_Idle' | 'Carry_Walk' | 'Damage' | 'Failure' | 'Fall' | 'Idle_2' | 'Idle' | 'Jump_Up' | 'Kick' | 'Land' | 'Pick_Up' | 'Roll_In_Place' | 'Roll' | 'Run_In_Place' | 'Run' | 'Sleep' | 'Talk' | 'Throw' | 'Victory' | 'Walk_In_Place' | 'Walk'
type GLTFActions = Record<ActionName, THREE.AnimationAction>

export default function Ratty({ animateRatty, ...props }: any) {
  // const ITEM_URI = `${process.env.REACT_APP_ASSETS_URL}/ratty.glb`;
  const ITEM_URI = `https://assets.unegma.net/moonland.land/ratty.glb`;

  const group = useRef<THREE.Group>()
  const { nodes, materials, animations } = useGLTF(ITEM_URI) as GLTFResult

  useEffect(() => {
    nodes.Eye_Left_Eyes_0.visible = false;
    nodes.Eye_Left_Eye_Shine_0.visible = false;
    nodes.Eye_Right_Eye_Shine_0.visible = false;
    nodes.Eye_Right_Eyes_0.visible = false;
  }, []);

  useEffect(() => {
    if (animateRatty === true) {
      console.log('helloo')
      handleAnimateRatty();
    }
  }, [animateRatty]);

  const handleAnimateRatty = () => {
    console.log('here')
    mixer.clipAction(animations[2], group.current).setDuration(4);
    mixer.clipAction(animations[2], group.current).play();
  }

  console.log(nodes)
  // @ts-ignore
  const { actions } = useAnimations<GLTFActions>(animations, group)

  // @ts-ignore
  const [mixer] = useState(() => new THREE.AnimationMixer())

  useEffect(() => {
    mixer.clipAction(animations[0], group.current).setDuration(4);
    mixer.clipAction(animations[0], group.current).play();
  }, [animations])

  useFrame((scene, delta) => {
    mixer?.update(delta)
  });

  return (
    // <group  dispose={null} >
    //   <group name="Scene">
        <group name="Rat" ref={group} {...props} rotation={[-1.57, 0, 0]}>
          <group name="Object_4" scale={1} >
            {/*<mesh name="Eye_Left_Eyes_0"  position={[0, 0, 20]} geometry={nodes.Eye_Left_Eyes_0.geometry} material={materials['Eyes']} />*/}
            {/*<mesh name="Eye_Right_Eye_Shine_0"  position={[0, 0, 20]} geometry={nodes.Eye_Right_Eye_Shine_0.geometry} material={materials['Eye_Shine']} />*/}
            {/*<mesh name="Eye_Left_Eye_Shine_0"  position={[0, 0, 20]} geometry={nodes.Eye_Left_Eye_Shine_0.geometry} material={materials['Eye_Shine']} />*/}
            {/*<mesh name="Eye_Right_Eyes_0" position={[0, 0, 20]} geometry={nodes.Eye_Right_Eyes_0.geometry} material={materials['Eyes']} />*/}

            <primitive object={nodes._rootJoint} />
            <group name="Object_6" position={[0, 0.03, -0.09]} />
            <group name="Object_8" position={[-1.07, -10.07, 53.71]} rotation={[0.75, 0.02, -0.01]} />
            <group name="Rat_Mesh" position={[0, 0.03, -0.09]} />
            <skinnedMesh name="Object_7" geometry={nodes.Object_7.geometry} material={materials.material} skeleton={nodes.Object_7.skeleton} >
              <meshPhongMaterial color="#ffffff" />

            </skinnedMesh>
            <skinnedMesh name="Object_9" geometry={nodes.Object_9.geometry} material={materials['default']} skeleton={nodes.Object_9.skeleton} >
              <meshPhongMaterial color="#ffffff" />

            </skinnedMesh>
          </group>
        </group>
      // </group>
    // </group>
  )
}
